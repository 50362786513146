const axios = require("axios");
const md5 = require("js-md5");
const qs = require("qs");
const ElementUI = require("element-ui");
//请求
module.exports = {
    commonRequest(url, data = {}) {
        return this.request(url, data, '');
    },
    userRequest(url, data = {}, is_file = false) {
        let token = window.sessionStorage.getItem("token");
        if (!token) {
            ElementUI.MessageBox.alert('登录超时，请重新登录', '提示', {
                confirmButtonText: '确定',
                callback: action => {
                    location.replace('/');
                }
            });
            return;
        }
        return this.request(url, data, token, is_file);
    },
    /**
     * 封装的request
     */
    request(url, data = {}, token = '', is_file = false) {

        let timeStamp = this.getTimeStamp();
        let sign = this.createSign(data, timeStamp);
        return new Promise((resolve, reject) => {
            let config = {
                method: "POST",
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Auth': token,
                    'timestamp': timeStamp,
                    'sign': sign
                },
                url: process.env.VUE_APP_API_URL + url,
                data: qs.stringify(data),
                validateStatus: (status => {
                    if (status === 200 || status === 403 || status === 406)
                        return true;
                    return false;
                })
            };
            if (is_file) {
                config['responseType'] = "blob";
            }
            axios(config).then(res => {
                if (res.status == 200) {
                    if (is_file) {
                        resolve(res.data);
                    } else {
                        if (res.data.code === 1) {
                            resolve(res.data);
                        } else {
                            ElementUI.Message({
                                message: res.data.message ? res.data.message : '请求失败',
                                type: 'error',
                                offset: 60
                            });
                            reject(res.data);
                        }
                    }
                } else if (res.status == 403 || res.status == 406) {
                    ElementUI.MessageBox.alert(res.data.message ? res.data.message : '登录超时，请重新登录', '提示', {
                        confirmButtonText: '确定',
                        callback: action => {
                            location.replace('/');
                        }
                    });

                } else {
                    ElementUI.Message({
                        message: '请求失败',
                        type: 'error',
                        offset: 60
                    });
                    reject(res);
                }
            }).catch(res => {
                ElementUI.Message({
                    message: '请求失败',
                    type: 'error',
                    offset: 60
                });
                reject(res);
            });
        });
    },
    //签名
    createSign(json, timeStamp) {
        let appKey = process.env.VUE_APP_API_KEY;
        return md5(timeStamp + appKey).toLowerCase();
    },
    getPageAuth(data) {
        let allMetas = document.getElementsByTagName('meta');
        let csrfParam = null;
        let csrfToken = null;
        for (let i = 0; i < allMetas.length; i++) {
            if (allMetas[i].name == "csrf-param") {
                csrfParam = allMetas[i].content;
            }
            if (allMetas[i].name == "csrf-token") {
                csrfToken = allMetas[i].content;
            }
        }
        if (csrfParam != null && csrfToken != null) {
            data[csrfParam] = csrfToken;
        }
        return data;
    },
    //获取时间戳
    getTimeStamp() {
        return new Date().getTime();
    }
}