import BasicLayout from '@/components/layout/index'
const meta = {
    auth: true
}

const pre = 'report_'

export default {
    path: '/report',
    name: 'report',
    redirect: {
        name: `${pre}sale`
    },
    meta,
    component: BasicLayout,
    children: [{
        path: 'sale',
        name: `${pre}sale`,
        meta: {
            auth: ['report-sale'],
            title: '销售报表'
        },
        component: () =>
            import ('@/views/report/sale')
    }, {
        path: 'vip',
        name: `${pre}vip`,
        meta: {
            auth: ['report-vip'],
            title: '会员报表'
        },
        component: () =>
            import ('@/views/report/vip')
    }]
}