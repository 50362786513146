import BasicLayout from '@/components/layout/index'
const meta = {
    auth: true
}

const pre = 'resource_'

export default {
    path: '/resource',
    name: 'resource',
    redirect: {
        name: `${pre}list`
    },
    meta,
    component: BasicLayout,
    children: [{
        path: 'list',
        name: `${pre}list`,
        meta: {
            auth: ['resource-list'],
            title: '图片列表'
        },
        component: () =>
            import ('@/views/resource/list')
    }, {
        path: 'sort',
        name: `${pre}sort`,
        meta: {
            auth: ['resource-sort'],
            title: '图片分类'
        },
        component: () =>
            import ('@/views/resource/sort')
    }]
}