<template>
  <el-submenu :index="menu.id + ''" v-if="menu.menus && menu.menus.length > 0">
    <template #title>
      <i class="icon" :class="menu.class_name" v-if="menu.class_name"></i>
      <span slot="title">{{ menu.name }}</span>
    </template>
    <sidebar
      v-for="inner in menu.menus"
      :key="inner.ID"
      :menu="inner"
    ></sidebar>
  </el-submenu>
  <el-menu-item :index="menu.id + ''" v-else @click="handleMenu(menu)">
    <i class="icon" :class="menu.class_name" v-if="menu.class_name"></i>
    <template #title
      ><span>{{ menu.name }}</span></template
    >
  </el-menu-item>
</template>
  <script>
//侧边菜单
export default {
  name: "sidebar",
  props: {
    menu: Object,
  },
  data() {
    return {};
  },
  methods: {
    handleMenu(menu) {
      if (menu.id == this.$store.state.menu.activeMenu) return;
      this.$store.commit("menu/activeMenu", menu.id);
      this.$router.push({
        path: menu.url,
      });
    },
  },
};
</script>