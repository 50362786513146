export default {
    namespaced: true,
    state: {
        activeMenu: '',
        menuList: []
    },
    mutations: {
        activeMenu(state, menuID) {
            state.activeMenu = menuID
        },
        menuList(state, menuList) {
            state.menuList = menuList
        }
    },
    actions: {}
}