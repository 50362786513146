<template>
  <el-container class="layout_center">
    <el-header class="layout_header" height="40px">
      <div class="main_header">
        <div class="logo">{{ appName }}</div>
        <div class="item" v-if="userInfo" @click="passwordDialog = true">
          {{ userInfo.username }}
        </div>
        <div class="item" @click="signOut">退出</div>
      </div>
      <setPassword
        ref="setPassword"
        v-if="passwordDialog"
        @dialogClose="passwordDialog = false"
      ></setPassword>
    </el-header>
    <el-container
      class="layout_container"
      :style="{ height: screenHeight + 'px' }"
    >
      <el-aside class="layout_menus" width="collapse">
        <el-menu :default-active="menuID + ''" :unique-opened="true">
          <sidebar
            v-for="item in menuJosn"
            :key="item.id"
            :menu="item"
          ></sidebar>
        </el-menu>
      </el-aside>
      <el-main class="layout_main">
        <router-view />
      </el-main>
    </el-container>
  </el-container>
</template>
<script>
import setPassword from "./module/setPassword";
import sidebar from "./module/sidebar";
export default {
  components: {
    sidebar,
    setPassword,
  },
  data() {
    return {
      appName: "",
      passwordDialog: false,
      screenHeight: window.innerHeight,
      isCollapse: false,
      menuID: "",
      menuJosn: [],
    };
  },
  created() {
    this.menuJosn = this.$store.state.menu.menuList;
    //获取用户信息
    this.userInfo = this.$store.state.userInfo;
    this.appName = process.env.VUE_APP_NAME;
    //获取当前活动菜单
    this.menuID = this.$store.state.menu.activeMenu;
    if (!this.menuID) {
      if (this.menuJosn[0].is_page == 1) {
        this.menuID = this.menuJosn[0].id + "";
      } else {
        this.menuID = this.menuJosn[0].menus[0].id + "";
      }
      this.$store.commit("menu/activeMenu", this.menuID);
    }
    window.addEventListener("resize", this.onResize);
  },
  methods: {
    //窗口缩放
    onResize() {
      this.screenHeight = window.innerHeight - 40;
    },
    //退出
    signOut() {
      this.$confirm("是否确实要退出登录?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(() => {
          this.$router.push({
            path: "/login",
          });
        })
        .catch(() => {});
    },
  },
};
</script>