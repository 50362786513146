//公共方法
module.exports = {
    //判断列表1中是否包含了列表2中的某一项
    includeArray(list1, list2) {
        let status = false
        if (list1 === true) {
            return true
        } else {
            if (typeof list2 !== 'object') {
                return false
            }
            list2.forEach(item => {
                if (list1.includes(item)) status = true
            })
            return status
        }
    },
    //获取月份
    getMonth() {
        let day = new Date();
        var year = day.getFullYear();
        var month = day.getMonth() + 1 + '';
        if (month.length == 1)
            month = '0' + month;
        return year + '-' + month;
    },
    getDay() {
        let date = new Date();
        var year = date.getFullYear();
        var month = date.getMonth() + 1 + '';
        if (month.length == 1)
            month = '0' + month;
        var day = date.getDay() + 1 + '';
        if (day.length == 1)
            day = '0' + day;
        return year + '-' + month + '-' + day;
    },
    //是否为空
    isEmpty(value) {
        return (value + '').trim() == '';
    },
    //是否为空
    isNullEmpty(value) {
        return value == null || value == 'undefined' || value == [] || (value + '').trim() == '';
    },
    //验证长度
    checkLen(value, min, max) {
        value = (value + '').trim();
        var len = value.length;
        return (len >= min && len <= max);
    },
    sizeName(size) {
        if (size < 1024 * 1024)
            return this.formatDecimal(size / 1024) + 'K';
        return this.formatDecimal(size / (1024 * 1024)) + 'M';
    },
    //四舍五入
    formatDecimal(num) {
        return Math.round(num * 100) / 100;
    },
    //判断是否价格
    isPrice(val) {
        var pattern = /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/;
        return pattern.test((val + '').trim());
    },
    //判断是否大于0的数字
    isZFloat(val) {
        var pattern = /^[1-9]\d*(\.\d+)?$/;
        return pattern.test((val + '').trim());
    },
    //判断是否正整数(包含0)
    isZeroInt(val) {
        var pattern = /^\d+$/;
        return pattern.test((val + '').trim());
    },
    getTimeName(time) {
        return time.split(" ")[0];
    }
}