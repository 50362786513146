export default {
    namespaced: true,
    state: {
        name: '',
        username: '',
        powers: []
    },
    mutations: {
        name(state, name) {
            state.name = name
        },
        username(state, username) {
            state.username = username
        },
        powers(state, powers) {
            state.powers = powers
        },
    },
    actions: {}
}