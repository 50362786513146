import BasicLayout from '@/components/layout/index'
const meta = {
    auth: true
}

const pre = 'staff_'

export default {
    path: '/staff',
    name: 'staff',
    redirect: {
        name: `${pre}list`
    },
    meta,
    component: BasicLayout,
    children: [{
        path: 'list',
        name: `${pre}list`,
        meta: {
            auth: ['staff-list'],
            title: '员工列表'
        },
        component: () =>
            import ('@/views/staff/list')
    }]
}