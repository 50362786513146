<template>
  <el-dialog
    title="修改密码"
    :visible.sync="visible"
    @close="dialogClose"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    width="400px"
  >
    <el-form
      ref="modelForm"
      :rules="modelRules"
      :model="modelForm"
      label-width="100px"
      size="small"
    >
      <el-form-item label="姓名：" size="small">
        {{ userInfo.name }}
      </el-form-item>
      <el-form-item label="用户名：" size="small">
        {{ userInfo.username }}
      </el-form-item>
      <el-form-item label="旧密码：" size="small" prop="old_password">
        <el-input
          v-model="modelForm.old_password"
          placeholder="旧密码"
          type="password"
          size="small"
        ></el-input>
      </el-form-item>
      <el-form-item label="密码：" size="small" prop="password">
        <el-input
          v-model="modelForm.password"
          placeholder="密码"
          type="password"
          size="small"
        ></el-input>
      </el-form-item>
      <el-form-item label="确认密码：" size="small" prop="com_password">
        <el-input
          v-model="modelForm.com_password"
          type="password"
          placeholder="确认密码"
          size="small"
        ></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" style="text-align: center">
      <el-button
        type="primary"
        native-type="submit"
        @click.native.prevent="handleSubmit"
        :loading="btnLogining"
        size="small"
        >确 定</el-button
      >
      <el-button @click="dialogClose" size="small">取 消</el-button>
    </div>
  </el-dialog>
</template>
<script>
import userApi from "@/api/user";
export default {
  name: "setPassword",
  data() {
    var valiPassword = (rule, value, callback) => {
      if (value !== this.modelForm.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      btnLogining: false,
      visible: true,
      modelForm: {
        old_password: "",
        password: "",
        com_password: "",
      },
      modelRules: {
        old_password: [
          { required: true, message: "请输入旧密码", trigger: "blur" },
        ],
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          { min: 4, max: 30, message: "长度为4至30个字符" },
        ],
        com_password: [
          { required: true, message: "请输入确认密码", trigger: "blur" },
          { min: 4, max: 30, message: "长度为4至30个字符" },
          { validator: valiPassword, trigger: "blur" },
        ],
      },
      userInfo: null,
    };
  },
  created() {
    this.userInfo = this.$store.state.userInfo;
  },
  methods: {
    //关闭
    dialogClose() {
      this.$emit("dialogClose", {});
    },
    //提交
    handleSubmit() {
      this.$refs.modelForm.validate((valid) => {
        if (valid) {
          this.btnLogining = true;
          userApi
            .userPassword(this.modelForm)
            .then((res) => {
              this.$message({
                message: "提交成功",
                type: "success",
                offset: 60,
              });
              this.dialogClose();
            })
            .catch((res) => {
              this.btnLogining = false;
            });
        } else {
          return false;
        }
      });
    },
  },
};
</script>
<style scope>
</style>