import Vue from "vue";
import VuexPersistence from 'vuex-persist'
import Vuex from "vuex";
import userInfo from './module/userInfo'
import menu from './module/menu'
Vue.use(Vuex);

export default new Vuex.Store({
    state: {},
    plugins: [
        new VuexPersistence({
            reducer: state => ({
                userInfo: state.userInfo,
                menu: state.menu
            }),
            storage: window.localStorage
        }).plugin
    ],
    mutations: {},
    actions: {},
    modules: {
        userInfo,
        menu
    },
});