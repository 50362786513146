import BasicLayout from '@/components/layout/index'
const meta = {
    auth: true
}

const pre = 'setting_'

export default {
    path: '/setting',
    name: 'setting',
    redirect: {
        name: `${pre}banner`
    },
    meta,
    component: BasicLayout,
    children: [{
        path: 'banner',
        name: `${pre}banner`,
        meta: {
            auth: ['setting-banner'],
            title: '广告列表'
        },
        component: () =>
            import ('@/views/setting/banner')
    }, {
        path: 'food',
        name: `${pre}food`,
        meta: {
            auth: ['setting-food'],
            title: '菜品列表'
        },
        component: () =>
            import ('@/views/setting/food')
    }]
}