import BasicLayout from '@/components/layout/index'
const meta = {
    auth: true
}

const pre = 'home_'

export default {
    path: '/',
    name: 'home',
    redirect: {
        name: `${pre}index`
    },
    meta,
    component: BasicLayout,
    children: [{
        path: 'home',
        name: `${pre}index`,
        meta: {
            auth: ['home'],
            title: '主页'
        },
        component: () =>
            import ('@/views/index/index')
    }]
}