import BasicLayout from '@/components/layout/index'
const meta = {
    auth: true
}

const pre = 'vip_'

export default {
    path: '/vip',
    name: 'vip',
    redirect: {
        name: `${pre}list`
    },
    meta,
    component: BasicLayout,
    children: [{
        path: 'list',
        name: `${pre}list`,
        meta: {
            auth: ['vip-list'],
            title: '会员列表'
        },
        component: () =>
            import ('@/views/vip/list')
    }, {
        path: 'label',
        name: `${pre}label`,
        meta: {
            auth: ['vip-label'],
            title: '会员标签'
        },
        component: () =>
            import ('@/views/vip/label')
    }, {
        path: 'tablelist',
        name: `${pre}tablelist`,
        meta: {
            auth: ['vip-table-list'],
            title: '预约列表'
        },
        component: () =>
            import ('@/views/vip/tablelist')
    }, {
        path: 'rechargelist',
        name: `${pre}rechargelist`,
        meta: {
            auth: ['vip-recharge-list'],
            title: '充值列表'
        },
        component: () =>
            import ('@/views/vip/rechargelist')
    }, {
        path: 'consumelist',
        name: `${pre}consumelist`,
        meta: {
            auth: ['vip-consume-list'],
            title: '消费列表'
        },
        component: () =>
            import ('@/views/vip/consumelist')
    }, {
        path: 'balancelist',
        name: `${pre}balancelist`,
        meta: {
            auth: ['vip-balance-list'],
            title: '余额明细'
        },
        component: () =>
            import ('@/views/vip/balancelist')
    }]
}