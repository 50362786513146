const requestApi = require("@/lib/request");
// 管理员接口
module.exports = {
    //管理员列表
    userList(data) {
        return new Promise((resolve, reject) => {
            requestApi.userRequest('/api/user/user-list', data).then(res => {
                resolve(res);
            }).catch(res => {
                reject(res);
            });
        });
    },
    //管理员操作
    userForm(data) {
        return new Promise((resolve, reject) => {
            requestApi.userRequest('/api/user/user-form', data).then(res => {
                resolve(res);
            }).catch(res => {
                reject(res);
            });
        });
    },
    //管理员操作
    userEdit(data) {
        return new Promise((resolve, reject) => {
            requestApi.userRequest('/api/user/user-edit', data).then(res => {
                resolve(res);
            }).catch(res => {
                reject(res);
            });
        });
    },
    //修改密码
    userPassword(data) {
        return new Promise((resolve, reject) => {
            requestApi.userRequest('/api/user/user-password', data).then(res => {
                resolve(res);
            }).catch(res => {
                reject(res);
            });
        });
    },
    //管理员重置密码
    userResetPassword(data) {
        return new Promise((resolve, reject) => {
            requestApi.userRequest('/api/user/user-reset-password', data).then(res => {
                resolve(res);
            }).catch(res => {
                reject(res);
            });
        });
    },
    //管理员状态
    userStatus(data) {
        return new Promise((resolve, reject) => {
            requestApi.userRequest('/api/user/user-status', data).then(res => {
                resolve(res);
            }).catch(res => {
                reject(res);
            });
        });
    },
    //删除管理员
    userDelete(data) {
        return new Promise((resolve, reject) => {
            requestApi.userRequest('/api/user/user-delete', data).then(res => {
                resolve(res);
            }).catch(res => {
                reject(res);
            });
        });
    },
    //权限分组详情
    groupInfo(data) {
        return new Promise((resolve, reject) => {
            requestApi.userRequest('/api/user/group-info', data).then(res => {
                resolve(res);
            }).catch(res => {
                reject(res);
            });
        });
    },
    ///权限分组列表
    groupList() {
        return new Promise((resolve, reject) => {
            requestApi.userRequest('/api/user/group-list', {}).then(res => {
                resolve(res);
            }).catch(res => {
                reject(res);
            });
        });
    },
    //权限分组操作
    groupForm(data) {
        return new Promise((resolve, reject) => {
            requestApi.userRequest('/api/user/group-form', data).then(res => {
                resolve(res);
            }).catch(res => {
                reject(res);
            });
        });
    },
    //删除权限分组
    groupDelete(data) {
        return new Promise((resolve, reject) => {
            requestApi.userRequest('/api/user/group-delete', data).then(res => {
                resolve(res);
            }).catch(res => {
                reject(res);
            });
        });
    },
    //登录日志列表
    loginLogList(data) {
        return new Promise((resolve, reject) => {
            requestApi.userRequest('/api/user/login-log-list', data).then(res => {
                resolve(res);
            }).catch(res => {
                reject(res);
            });
        });
    },
}