export default [
    // 登录
    {
        path: "/login",
        name: "login",
        meta: {
            title: '登录'
        },
        component: () =>
            import ('@/views/login')
    }
]