import Vue from "vue";
import VueRouter from "vue-router";
import routes from './routers'
import store from '@/store'
import { includeArray } from '@/lib/util'
Vue.use(VueRouter);
const router = new VueRouter({
    routes
});
router.beforeEach((to, from, next) => {
        if (to.matched.some(_ => _.meta.auth)) {
            const token = window.sessionStorage.getItem("token");
            if (token && token !== 'undefined') {
                const access = store.state.userInfo.powers
                const isPermission = includeArray(to.meta.auth, access)
                if (isPermission) {
                    next()
                } else {
                    if (access.length == 0) {
                        next({
                            name: 'login',
                            // query: {
                            //     redirect: to.fullPath
                            // }
                        })
                        localStorage.clear()
                        sessionStorage.clear()
                    } else {
                        next({
                            name: '403'
                        })
                    }
                }
            } else {
                next({
                    name: 'login',
                    // query: {
                    //     redirect: to.fullPath
                    // }
                })
            }
        } else {
            next();
        }
    })
    /**
     * 路由拦截
     * 权限验证
     */

// router.beforeEach(async (to, from, next) => {
//     // 判断是否需要登录才可以进入

//     if(to.matched.some(_ => _.meta.auth)) {
//         // 这里依据 token 判断是否登录，可视情况修改
//         const token =window.sessionStorage.getItem("token");
//         if(token && token !== 'undefined') {
// 			next()
//             // const access = store.state.userInfo.uniqueAuth
//             // const isPermission = includeArray(to.meta.auth, access)
//             // if(isPermission) {
//             //     next()
//             // } else {
//             //     if(access.length == 0) {
//             //         next({
//             //             name: 'login',
//             //             query: {
//             //                 redirect: to.fullPath
//             //             }
//             //         })
//             //         localStorage.clear()
//             //         removeCookies('token')
//             //         removeCookies('expires_time')
//             //         removeCookies('uuid')
//             //     } else {
//             //         next({
//             //             name: '403'
//             //         })
//             //     }
//            // }
//             // next();
//         } else {
//             // 没有登录的时候跳转到登录界面
//             // 携带上登陆成功之后需要跳转的页面完整路径
//             next({
//                 name: 'login',
//                 query: {
//                     redirect: to.fullPath
//                 }
//             })
//         }
//     } else {
//         // 不需要身份校验 直接通过
//         next()
//     }
// })
router.afterEach(to => {
    // 返回页面顶端
    window.scrollTo(0, 0)
})

export default router;