import BasicLayout from '@/components/layout/index'
const meta = {
    auth: true
}

const pre = 'user_'

export default {
    path: '/user',
    name: 'user',
    redirect: {
        name: `${pre}list`
    },
    meta,
    component: BasicLayout,
    children: [{
        path: 'list',
        name: `${pre}list`,
        meta: {
            auth: ['user-list'],
            title: '管理员列表'
        },
        component: () =>
            import ('@/views/user/list')
    }, {
        path: 'groups',
        name: `${pre}groups`,
        meta: {
            auth: ['user-groups'],
            title: '权限组列表'
        },
        component: () =>
            import ('@/views/user/groups')
    }, {
        path: 'loginlog',
        name: `${pre}loginlog`,
        meta: {
            auth: ['user-loginlog'],
            title: '登陆日志'
        },
        component: () =>
            import ('@/views/user/loginlog')
    }]
}